angular.module('LeasePilot').directive('helpWidget', [
  '$timeout',
  '$http',
  '$rootScope',
  '$mdSidenav',
  'ApiService',
  function(
    $timeout,
    $http,
    $rootScope,
    $mdSidenav,
    ApiService,
  ) {
    return {
      restrict: 'E',
      scope: {
        showContact: "=",
      },
      templateUrl: 'help-widget.html',
      link: async function(scope, element) {
        scope.sidenav = $mdSidenav('help-widget');

        ApiService.getChangelog().then(function(response) {
          scope.changelogItems = response.data.changelog.reverse();
          scope.changelogNew = false;

          const changelogVersion = localStorage.getItem('lp:changelog:version');

          if (changelogVersion !== scope.changelogItems[0].time) {
            scope.state.go('changelog');
            scope.sidenav.open();
            scope.changelogNew = true;
            localStorage.setItem('lp:changelog:version', scope.changelogItems[0].time);
          }
        });

        scope.state = (function() {
          var current = 'menu';
          scope.$watch(
            function() {
              return current;
            },
            function(toEnter, toExit) {
              scope[toEnter] &&
                scope[toEnter].onEnter &&
                scope[toEnter].onEnter();
              $timeout(function() {
                scope[toExit] && scope[toExit].onExit && scope[toExit].onExit();
              }, 400);
            },
          );
          return {
            is: function(state) {
              return current === state;
            },
            go: function(state) {
              current = state;
            },
            reset: function() {
              scope.sidenav.close();
              localStorage.setItem('help_panel_closed_by_user', true);
              $timeout(function() {
                scope.state.go('menu');
              }, 300);
            },
          };
        })();

        scope.zendesk = (function() {
          var zendesk = {};

          zendesk.showAssetChangeInfoMessage = false;
          zendesk.showAddNewUserInfoMessage = false;

          zendesk.onEnter = function() {
            zendesk.sent = false;
            zendesk.sending = false;
            zendesk.status = null;
            zendesk.files.list.length = 0;
            zendesk.fields = {
              name: '',
              message: '',
              email: $rootScope.user.email || '',
              type : clipboardManager.zendesk_select_type ? 'formatting_issue' : '',
            };
            if(clipboardManager.zendesk_select_type){
              clipboardManager.zendesk_select_type = false;
            }
            scope.zendeskForm.$setUntouched();

            window.track.event(new OpenContactFormPaneEvent({
              context: $rootScope.getContext(),
            }));
          };

          zendesk.files = {
            list: [],
            push: function(files, $event) {
              [].push.apply(zendesk.files.list, files);
              $event.target.value = null;
            },
            pull: function(file) {
              _.pull(zendesk.files.list, file);
            },
          };

          zendesk.validate = function() {
            scope.safeApply(function() {
            zendesk.showAssetChangeInfoMessage = false;
            zendesk.showAddNewUserInfoMessage = false;

            if (zendesk.fields.type === "asset_change") {
              zendesk.showAssetChangeInfoMessage = true;
            } else if (scope.zendesk.fields.type === "add_new_user") {
              zendesk.showAddNewUserInfoMessage = true;
            }
          });
          };

          zendesk.submit = function() {
            if (scope.zendeskForm.$invalid) {
              return;
            }
            var data = new FormData();
            _(zendesk.fields).forEach(function(value, key) {
              data.append(key, value);
            });
            _(zendesk.files.list).forEach(function(file) {
              data.append('files[]', file);
            });
            var options = {
              transformRequest: angular.identity,
              headers: { 'Content-Type': undefined },
            };
            zendesk.sending = true;
            zendesk.status = "SENDING";
            $http
              .post('/api/zendesk_requests', data, options)
              .then(function() {
                zendesk.sending = false;
                zendesk.status = "SENT";
                window.track.event(new ContactFormSubmittedEvent({
                  hasAttachments: zendesk.files.list.length > 0,
                  context: $rootScope.getContext(),
                }));
              })
              .catch(function() {
                zendesk.sending = false;
                zendesk.status = "ERROR";
              });
          };

          window.zendesk = scope;
          return zendesk;
        })();

        scope.changelog = (function() {
          var changelog = {};
          changelog.onEnter = function() {
            window.track.event(new OpenChangelogPaneEvent({
              context: $rootScope.getContext(),
            }));
          };
          window.changelog = scope;
          return changelog;
        })();

        //
        function init(data) {
          
        }
      },
    };
  },
]);

angular.module('LeasePilot').directive('hwExpanded', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var duration = 200;
      scope.$watch(attrs.hwExpanded, function(expanded) {
        expanded ? element.slideDown(duration) : element.slideUp(duration);
      });
    },
  };
});
